

const razasPerros = [
      "Affenpinscher",
      "Afgano",
      "Airedale Terrier",
      "Akbash",
      "Akita americano",
      "Akita inu",
      "Alaska malamute",
      "American Pit Bull Terrier",
      "American pitbull",
      "American staffordshire terrier",
      "Azawakh",
      "Basenji",
      "Basset hound",
      "Beagle",
      "Beauceron",
      "Bedlington terrier",
      "Bernés de la montaña",
      "Bichón frisé",
      "Bichón habanero",
      "Bloodhound",
      "Boerboel",
      "Boloñés",
      "Border collie",
      "Border terrier",
      "Borzoi",
      "Boston terrier",
      "Boxer",
      "Boyero de appanzell",
      "Boyero de Flandes",
      "Boyero entlebuch",
      "Boykin Spaniel",
      "Bull Terrier",
      "Bull terrier inglés",
      "Bulldog americano",
      "Bulldog francés",
      "Bulldog inglés",
      "Bullmastiff",
      "Cairn terrier",
      "Caniche",
      "Cavalier king",
      "Cazador de alces noruego",
      "Chihuahua",
      "Chow chow",
      "Cirneco Dell Etna",
      "Clumber Spaniel",
      "Cockapoo",
      "Cocker americano",
      "Cocker spaniel inglés",
      "Collie barbudo",
      "Coonhound",
      "Corgi galés de pembroke",
      "Coton de tutelar",
      "Crestado chino",
      "Cruzado/Criollo",
      "Dachshund",
      "Dachsund o tekel",
      "Dandi Dimmont terrier",
      "de presa canario",
      "Doberman",
      "Dogo Argentino",
      "Dogo de Burdeos",
      "Dálmata",
      "Dóberman",
      "Esquimal americano",
      "Fila Brasileiro",
      "Fila brasilero",
      "Fox hound",
      "Fox terrier pelo de alambre",
      "Fox terrier pelo liso",
      "Foxhound",
      "French poodle",
      "Galgo español",
      "Galgo inglés",
      "Golden retriever",
      "Gran boyero suizo",
      "Gran danés",
      "Grifón de Bruselas",
      "Grifón vandeano basset pequeño",
      "Husky siberiano",
      "Jack rusell terrier",
      "Kelpie australiano",
      "Komondor",
      "Kuvazs",
      "Labrador",
      "Labrel persa",
      "Lacy azul",
      "Laika",
      "Lakeland terrier",
      "Lebrel inglés",
      "Lhasa apso",
      "Lobero irlandés",
      "Maltés",
      "Mastin inglés",
      "Mastín azul",
      "Mastín de pirineo",
      "Mastín Napolitano",
      "Mastín tibetano",
      "Papillón",
      "Pastor alemán",
      "Pastor australiano",
      "Pastor autraliano",
      "Pastor belga",
      "Pastor brie",
      "Pastor collie",
      "Pastor de anatolia",
      "Pastor de briard",
      "Pastor de caucaso",
      "Pastor de pirineos",
      "Pastor de Valée",
      "Pastor ganadero autraliano",
      "Pastor holandés",
      "Pastor inglés",
      "Pastor islandés",
      "Pastor vasco",
      "Pekinés",
      "Pequeño perro león",
      "Perro cantor de Nueva Guinea",
      "Perro chindo",
      "Perro de agua americano",
      "Perro de agua español",
      "Perro de Canaan",
      "Perro de Carolina",
      "Perro Finlandés de Laponia",
      "Perro Kai",
      "Perro sin pelo de Perú",
      "Pharaoh hound",
      "Pinscher",
      "Pit Bull Terrier",
      "Plott hound",
      "Podenco Ibicenco",
      "Podenco portugues",
      "Pointer alemán",
      "Pointer inglés",
      "Pomerania",
      "Pomeranio",
      "Presa canario",
      "Pug",
      "Retriever Chesapeake",
      "Retriever de pelo rizado",
      "Rhodesian ridge back",
      "Rodesiano",
      "Rottweiler",
      "Saluki",
      "Samoyedo",
      "San bernardo",
      "Schipperke",
      "Schnauzer gigante",
      "Schnauzer standard",
      "Setter escocés",
      "Setter inglés",
      "Setter irlandés",
      "Shar pei",
      "shetland sheepdog",
      "Shiba Inu",
      "Shih tzu",
      "Spaniel de campo",
      "Spaniel japonés",
      "Spinone",
      "Spitz",
      "Springer spaniel inglés",
      "Staffordshire Terrier",
      "Terranova",
      "Terrier americano sin pelo",
      "Terrier australiano",
      "Terrier escocés",
      "Terrier negro ruso",
      "Tosa",
      "Tosa Japonés",
      "Toy",
      "Vallhund sueco",
      "Viszla",
      "Weimaraner",
      "West highland white terrier",
      "Whippet",
      "Yorkshire terrier",
],
    

razasGatos =  [
      "Abisionio",
      "Americano pelo de alambre",
      "Angora turco",
      "Azul ruso",
      "Balinés",
      "Bengalí",
      "Birmano",
      "Bobtail japonés",
      "Bombay",
      "Burmés",
      "Café habano",
      "Charteux",
      "Cornish rex",
      "Cruzado/Criollo",
      "Escocés de orejas caídas",
      "Esfinge",
      "Gato noruego del bosque",
      "Himalayo",
      "Javanés",
      "Korat",
      "Maine coon",
      "Manx",
      "Mau egipcio",
      "Mexicano pelicorto",
      "Ocicat",
      "Pelicorto americano",
      "Pelicorto británico",
      "Pelicorto exótico",
      "Pelicorto oriental",
      "Persa",
      "Ragdoll",
      "Rex devon",
      "Rizado americano",
      "Siamés",
      "Singapura",
      "Snow shoe",
      "Tokinés",
      "Toyger",
    ]

export  {razasPerros, razasGatos};