<template>
  <v-app id="app">
    <div class="principal_container">
      <v-container class="container_logos">
        <img
          class="logo_sura"
          src="https://storage.googleapis.com/experpluscomfotos/logo_sura.png"
          v-bind:style="{ 'margin-right': '0%' }"
        />
      </v-container>
       <template>
        <v-container class="container_img_principal">
          <v-img
            class="img_principal"
            src="../assets/SegurosMascotas.png"
          ></v-img>
        </v-container>
        <v-container class="container_title">
          <h2 class="title-principal">
            Adquiere fácil y rápido un seguro para
          </h2>
          <h2 class="text-normal-pink title-principal"> tu amor de cuatro patitas</h2>
          <v-container>
            <span class="text-normal text_bold"> Comprar aquí</span>
            <span class="text-normal">, protege a tu mascota con un seguro adaptado a tus necesidades, en los siguientes siniestros:
            </span>
            <br />
            <v-container class="container_items">
              <span>
                1) Gastos veterinarios
                <br />
                2) Pérdida de tu mascota
                <br />
                3) Gastos funerarios (incluye exequias y eutanasia)
                <br />
                4) Daños a terceros
              </span>
            </v-container>
            <br />
          </v-container>
          <h2 class="text-normal-pink text_bold">Más información aquí</h2>
          <v-container class="centerVideo">
            <youtube width="centerVideo" video-id="Vn_zRlJiqqs" ref="youtube"></youtube>
          </v-container>

          <h2 class="text-normal-pink">Compra aquí tu plan</h2>
          <v-container>
            <v-container>
              <v-select
                v-model="tMascota"
                :items="tipoMascota"
                label="¿Qué tipo de mascota tienes?"
              ></v-select>
            </v-container>
            <img
              class="img_border"
              v-if="tMascota == null"
              src="https://experplus.com/wp-content/uploads/2021/04/perro-gato-mascotas.jpg"
              v-bind:style="{ right: '2%' }"
              width="100%"
            />
            <v-container v-if="tMascota != null">
              <v-select
                v-model="plan"
                :items="planes"
                label="Seleciona tu plan aquí"
                @input="consultar()"
              ></v-select>
              <v-container v-if="plan == 'Plan mascotas básico'">
                <img
                  transition="scroll-y-transition"
                  src="https://storage.googleapis.com/experpluscomfotos/sura/BasicoWeb.png"
                  width="100%"
                />
              </v-container>
              <v-container
                transition="scroll-y-transition"
                v-if="plan == 'Plan mascotas clásico'"
              >
                <img
                  src="https://storage.googleapis.com/experpluscomfotos/sura/ClasicoWeb.png"
                  width="100%"
                />
              </v-container>
              <v-container
                transition="scroll-y-transition"
                v-if="plan == 'Plan mascotas global'"
              >
                <img
                  src="https://storage.googleapis.com/experpluscomfotos/sura/GlobalWeb.png"
                  width="100%"
                />
              </v-container>
              <v-select
                v-if="tMascota != null && plan != null"
                @input="consultar()"
                id="mySelect"
                v-model="fPago"
                :items="formaPago"
                label="¿Cómo te gustaría pagar?"
              ></v-select>
            </v-container>
            <v-container
              v-if="plan != null && tMascota != null && fPago != null"
              class="text_center"
            >
              <h2 class="title-principal size_xmax">
                Valor {{ fPago.toLowerCase() }} :
                {{
                  this.precio
                    | currency("$", 0, {
                      thousandsSeparator: ".",
                    })
                }}
              </h2>
            </v-container>
          </v-container>
        </v-container>
        <v-container v-if="plan != null && fPago != null && tMascota != null">
          <v-container>
            <h2 class="title-principal">
              {{ info }}
            </h2>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  @change="completo"
                  v-model="nombreCompleto"
                  label="Nombres y apellidos completos"
                  type="text"
                  :rules="rules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  @change="completo"
                  v-model="noDoc"
                  label="No. cédula"
                  type="number"
                  :rules="rules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDate"
                      label="Fecha de nacimiento"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="completo"
                    locale="ES"
                    v-model="birthDate"
                    @input="menu = false"
                    no-title
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  @change="completo"
                  v-model="phone"
                  label="No. contacto / celular"
                  type="number"
                  :rules="rulesNo"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  id="mySelect"
                  v-model="genderTipe"
                  :items="gender"
                  label="Seleciona el género"
                  @change="completo"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  @change="completo"
                  v-model="email"
                  label="Correo"
                  :rules="rulesEmail"
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  @change="completo"
                  :rules="rulesEmail"
                  hide-details="auto"
                  v-model="cEmail"
                  label="Confirmación del correo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="department"
                  id="mySelect"
                  :items="departamentos"
                  label="Seleciona el departamento"
                  @input="consultarCiudad()"
                  @change="completo"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  @change="completo"
                  v-model="city"
                  id="mySelect"
                  :items="ciudades"
                  label="Seleciona la Ciudad"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  @change="completo"
                  v-model="adress"
                  label="Dirección / Barrio"
                  :rules="rules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <h2 class="title-principal">
              {{ infoM }}
            </h2>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  @change="completo"
                  v-model="nameA"
                  label="Nombre de tu mascota"
                  :rules="rules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" v-if="tMascota != null">
                <v-select
                  @change="completo"
                  v-model="razaTipo"
                  id="mySelect"
                  :items="tMascota == 'Perro' ? razaAp : razaAg"
                  label="Seleciona la raza"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  @change="completo"
                  v-model="generoTipo"
                  id="mySelect"
                  :items="generoA"
                  label="Seleciona el género"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-menu
                  ref="menuA"
                  v-model="menuA"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="nacimientoA"
                      label="Fecha de nacimiento"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @change="completo"
                    locale="ES"
                    v-model="nacimientoA"
                    @input="menuA = false"
                    no-title
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-checkbox v-model="tyc">
              <template v-slot:label>
                <div>
                  Acepto los
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                      @click="openTYC()"
                        v-on="on"
                        target="_black"
                      >
                        Términos y Condiciones y Políticas de Privacidad (Habeas
                        Data)
                      </a>
                    </template>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-container>
          <v-container>
            <template class="text_center">
              <v-container>
                <span
                  :class="
                    confirmarDatos == false
                      ? 'text-normalGB'
                      : 'text-normal size_max text_bold'
                  "
                  >Tus datos están completos, al confirmar,</span
                >
                <span class="text-normal-pink size_max text_bold">
                  recibirás
                </span>
                <span> en las próximas 24 horas,</span>
                <span
                  :class="
                    confirmarDatos == false
                      ? 'text-normalGB'
                      : 'text-normal-pink size_max text_bold'
                  "
                >
                  dos (2) correos
                </span>
                <span
                  :class="
                    confirmarDatos == false
                      ? 'text-normalGB'
                      : 'text-normal size_max text_bold'
                  "
                  >de SURA con la</span
                >
                <span class="text-normal-pink text_bold size_max"> póliza </span>
                <span
                  :class="
                    confirmarDatos == false
                      ? 'text-normalGB'
                      : 'text-normal size_max text_bold'
                  "
                >
                  y otro con la
                </span>
                <span class="text-normal-pink text_bold size_max">
                  factura para pago.
                </span>
              </v-container>
              <v-container class="text_center">
                <v-btn
                  :color="confirmarDatos == false ? 'grey' : '#ff00b2'"
                  @click="save()"
                >
                  {{
                    confirmarDatos == false ? "Información incompleta" : "Comprar"
                  }}
                </v-btn>
              </v-container>
            </template>
            <v-dialog v-model="dialog" persistent max-width="290">
              <v-card>
                <v-card-title class="headline"> Felicitaciones ! </v-card-title>
                <v-card-text>
                  <span class="title_normalG">
                    Se inició el proceso para proteger a tu mascota con</span
                  >
                  <span class="title_normal"> SURA.</span>
                </v-card-text>
                <v-card-subtitle>
                  <span class="title_normalG">
                    Recuerda estar pendiente de tu correo
                  </span>
                </v-card-subtitle>
                <center v-bind:style="{ 'margin-bottom': '5%' }">
                  <img
                    src="https://storage.googleapis.com/experpluscomfotos/PerroGato.gif"
                    width="50%"
                  />
                </center>
                <v-container v-bind:style="{ 'text-align': 'center' }">
                  <img
                    src="https://experplus.com/wp-content/uploads/2021/04/suraCards.png"
                    width="40%"
                  />
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#ff00b2" text @click="aceptar()"> Aceptar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
        </v-container>
        <v-footer dark padless>
          <v-card
            flat
            tile
            color="#008ae6"
            class="white--text text-center"
            v-bind:style="{ width: '100%' }"
          >
            <v-card-text>
              <v-btn
                v-for="icon in icons"
                :key="icon.name"
                :href="icon.link"
                class="mx-4 white--text"
                icon
              >
                <v-icon size="24px">{{ icon.name }}</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text class="whiteM pt-0">
              <span class="text-corre text_bold"> Corresponsal de seguros </span>
            </v-card-text>
            <img
              class="logo_sura mt-0"
              src="https://storage.googleapis.com/experpluscomfotos/logo_sura.png"
            />
            <v-card-text class="white--text pt-0">
              Powered by: Capital Branch SAS
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="white--text">
              {{ new Date().getFullYear() }}<strong></strong>
            </v-card-text>
          </v-card>
        </v-footer>
      </template> 
       <v-btn
        color="green"
        fab
        float
        medium
        target="_blank"
        dark
        href="https://api.whatsapp.com/send?phone=573188663716&text=Hola%20SURA%2C%20deseo%20contacto%20para%20adquirir%20un%20seguro%20para%20mi%20mascota%20"
        class="v-btn--example btn_WA"
        @click="ads"
      >
        <v-img
          class="img_wa"
          src="https://es.logodownload.org/wp-content/uploads/2018/10/whatsapp-logo-11.png"
        />
      </v-btn>
      <v-dialog v-model="dialog" :width="popup_width">
        <v-card >
          <v-card-subtitle>
            <v-img  
              :src="require('../assets/suraPopup.png')">
            </v-img>
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false" text > Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
    </div>
  </v-app>
</template>


<script type="application/javascript" async src="https://www.googletagmanager.com/gtag/js?id=AW-738641187"></script>

<script>
import { host } from "../components/variables";
import axios from "axios";
import {razasPerros, razasGatos} from '../Tools/ListData.js'


window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "AW-738641187");

export default {
  name: "App",
  components: {},
  data: () => ({
    popup_width: "30% !important",
    dialog: false,
    icons: [
      { name: "mdi-email", link: "mailto://soporte@experplus.com" },
      { name: "mdi-phone", link: "http://wa.me/573188663716" },
    ],
    date: new Date().toISOString().substr(0, 10),
    rulesNo: [
      (value) => !!value || "Campo Requerido.",
      (value) => Number(value) || "Este campo debe ser un numero",
    ],
    rules: [
      (value) => !!value || "Campo Requerido.",
      (value) => value.length > 2 || "Campo invalido",
    ],
    rulesEmail: [
      (value) => {
        if (value.length > 0) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo invalido";
        }
      },
    ],
    menu: false,
    menuA: false,
    periodos: new Map([
      [1, "Mensual"],
      [6, "Semestral"],
      [12, "Anual"],
    ]),
    periodosInv: new Map([
      ["Mensual", 1],
      ["Semestral", 6],
      ["Anual", 12],
    ]),
    seguroInfo: null,
    fullData: null,
    planes: [
      "Plan mascotas básico",
      "Plan mascotas clásico",
      "Plan mascotas global",
    ],
    formaPago: [],
    tipoMascota: ["Perro", "Gato"],
    plan: null,
    fPago: null,
    tMascota: null,
    precio: null,
    cotiza: false,
    info: "Tus datos personales",
    infoM: "Datos de tu mascota",
    pName: null,
    sName: null,
    pLastname: null,
    sLastname: null,
    doc: ["Cédula de ciudadanía"],
    tipoDoc: null,
    nombreCompleto: null,
    noDoc: null,
    birthDate: null,
    age: null,
    phone: null,
    gender: ["Femenino", "Masculino"],
    genderTipe: null,
    email: null,
    cEmail: null,
    confirmadoEmail: false,
    adress: null,
    geoData: null,
    ciudades: ["Bucaramanga"],
    city: null,
    departamentos: ["Santander"],
    department: null,
    nameA: null,
    razaAp: razasPerros,
    razaAg: razasGatos,
    razaTipo: null,
    generoA: ["Macho", "Hembra"],
    generoTipo: null,
    nacimientoA: null,
    edadA: null,
    dialog: false,
    id: null,
    tyc: true,
    confirmarDatos: false,
  }),

  mounted() {
    axios.get(host + "/getInfoSeguros").then((result) => {
      this.fullData = result.data.rta.Mascota.data;
      console.log(this.fullData);
    });
    axios
      .get(
        "https://raw.githubusercontent.com/marcovega/colombia-json/master/colombia.min.json"
      )
      .then((result) => {
        this.geoData = result.data;
        this.departamentos = [];
        for (let i = 0; i < this.geoData.length; i++) {
          const element = this.geoData[i];
          this.departamentos.push(element.departamento);
        }
      });
      this.dialog = true;
      if(window.innerWidth < '600'){
        this.popup_width = '80%'
      }
  },
  methods: {
    downloadURI(url, filename) {
      fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        // headers: {
        //   "Origin": "http://127.0.0.1:8000"
        //   // 'Content-Type': 'application/pdf'
        //   // 'Content-Type': 'application/x-www-form-urlencoded',
        // },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }).then(function (t) {
        return t.blob().then((b) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", filename);
          a.click();
        });
      });
    },
    clausulas() {
      this.downloadURI(
        "https://mensajeros-alianza.s3.amazonaws.com/clausulado.pdf",
        "clausulado.pdf"
      );
    },
    edad() {
      this.age = this.$moment().diff(this.birthDate, "years");
    },
    edadm() {
      this.edadA = this.$moment().diff(this.nacimientoA, "month");
      if (this.edadA < 120 && this.edadA > 3) {
        console.log(this.edadA);
      } else {
        alert("La edad de tu mascota debe ser de 3 meses a 10 años");
      }
    },
    consultarCiudad() {
      for (let i = 0; i < this.geoData.length; i++) {
        const element = this.geoData[i];
        if (this.department == element.departamento) {
          // Significa que este es el departamento, entonces
          // a poner las ciudades
          this.ciudades = element.ciudades;
        }
      }
    },
    consultar() {
      if (this.plan != null && this.tMascota != null) {
        var data = this.fullData[this.tMascota]["data"];
        this.formaPago = [];
        for (var j = 0; j < data.length; j++) {
          if (data[j]["nombre"] == this.plan) {
            this.formaPago.push(this.periodos.get(data[j]["frecuencia"]));
          }
        }
        if (this.tMascota != null && this.fPago != null && this.plan != null) {
          var data2 = this.fullData[this.tMascota]["data"];
          for (var i = 0; i < data2.length; i++) {
            if (this.periodos.get(data2[i]["frecuencia"]) == this.fPago) {
              if (
                this.plan
                  .toLowerCase()
                  .includes(data2[i]["tipo_plan"].toLowerCase())
              ) {
                console.log(data2);
                this.precio = data2[i]["precio"];
                this.id = data2[i]["id"];
              }
            }
          }
        }
      }
    },
    completo() {
      if (
        this.plan == null ||
        this.fPago == null ||
        this.tMascota == null ||
        this.noDoc == null ||
        this.nombreCompleto == null ||
        this.birthDate == null ||
        this.phone == null ||
        this.genderTipe == null ||
        this.email == null ||
        this.cEmail == null ||
        this.adress == null ||
        this.city == null ||
        this.department == null ||
        this.nameA == null ||
        this.razaTipo == null ||
        this.generoTipo == null ||
        this.nacimientoA == null ||
        this.tyc == false ||
        this.plan == "" ||
        this.fPago == "" ||
        this.tMascota == "" ||
        this.noDoc == "" ||
        this.nombreCompleto == "" ||
        this.birthDate == "" ||
        this.phone == "" ||
        this.genderTipe == "" ||
        this.email == "" ||
        this.cEmail == "" ||
        this.adress == "" ||
        this.city == "" ||
        this.department == "" ||
        this.nameA == "" ||
        this.razaTipo == "" ||
        this.generoTipo == "" ||
        this.nacimientoA == ""
      ) {
        this.confirmarDatos = false;
        console.log("no tiene todos los datos");
      } else {
        this.confirmarDatos = true;
      }
      console.log(this.confirmarDatos);
    },
    save() {
      if (
        this.plan == null ||
        this.fPago == null ||
        this.tMascota == null ||
        this.noDoc == null ||
        this.nombreCompleto == null ||
        this.birthDate == null ||
        this.phone == null ||
        this.genderTipe == null ||
        this.email == null ||
        this.cEmail == null ||
        this.adress == null ||
        this.city == null ||
        this.department == null ||
        this.nameA == null ||
        this.razaTipo == null ||
        this.generoTipo == null ||
        this.nacimientoA == null ||
        this.tyc == false
      ) {
        this.dialog = false;
        alert("Debe llenar todos los campos requeridos");
      } else {
        if (this.email != this.cEmail) {
          alert("Los correos deben coincidir");
        } else {
          const fd = new FormData();
          fd.append("idSeguro", this.id);
          fd.append("nombreCompleto", this.nombreCompleto);
          fd.append("nombreM", this.nameA);
          fd.append("raza", this.razaTipo);
          fd.append("correo", this.cEmail);
          fd.append("birtdate", this.nacimientoA);
          fd.append("genero", this.generoTipo);
          fd.append("edad", this.edadA);
          fd.append("nacT", this.birthDate);
          fd.append("docT", this.noDoc);
          fd.append("tipoDT", this.tipoDoc);
          fd.append("edadT", this.age);
          fd.append("genT", this.genderTipe);
          fd.append("phone", this.phone);
          fd.append("direccion", this.adress);
          fd.append("departamento", this.department);
          fd.append("ciudad", this.city);
          axios.post(host + "/compraSeguroWeb", fd).then((result) => {
            let rta = result.data.mensaje;
            if (rta == "OK") {
              this.dialog = true;
              gtag("event", "compra_seguro");
              gtag("event", "conversion", {
                send_to: "AW-738641187/cPkaCLefo4oCEKOKm-AC",
                value: this.precio,
                currency: "COP",
                transaction_id: "",
              });
            } else {
              alert("vuelve a presionar el boton de enviar, por favor");
            }
          });
        }
      }
    },
    aceptar() {
      this.dialog = false;
      this.plan = null;
      this.fPago = null;
      this.tMascota = null;
      this.nombreCompleto = null;
      this.noDoc = null;
      this.birthDate = null;
      this.phone = null;
      this.genderTipe = null;
      this.email = null;
      this.cEmail = null;
      this.adress = null;
      this.city = null;
      this.department = null;
      this.nameA = null;
      this.razaTipo = null;
      this.generoTipo = null;
      this.nacimientoA = null;
    },
    ads() {
      gtag("event", "sura_wa");
    },
    openTYC(){
      let route = this.$router.resolve({path: '/terminos-y-condiciones'});
      window.open(route.href, '_blank');
    }
  },
};
</script>

<style lang="scss" >

.principal_container{
  background-size:cover !important;
  max-width: 100%;
  max-height: 100%;
  
}

.container_logos {
  text-align: center !important;
  width: 100% !important;
}

.container_title {
  text-align: justify;
}

.text_center {
  text-align: center;
}

.title-principal {
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
  color: #0300a3;
}

// textos
.text-normal {
  text-align: center;
  color: #0300a3;
}

.text-corre {
  text-align: center;
  font-size: 25px !important;
  color: white;
}

.text-normal-pink {
  text-align: center;
  color: #ff00b9;
}

.text-normalGB {
  text-align: center;
  color: #5a5a5a;
  font-size: 20px;
}

.text_bold {
  font-weight: bold;
}
.size_max {
  font-size: 20px;
}

.size_xmax {
  font-size: 35px;
}

.container_items {
  text-align: left;
  color: #0300a3;
}


.img_border {
  border-radius: 5%;
}

.field {
  text-decoration-color: white !important;
  color: #0300a3 !important;
  outline: none;
  font-size: 30px;
  padding-right: 10px;
  border-radius: 10px;
  transition: 0.3s border-color;
  margin: 10px 0;
  padding: 5px;
  width: 90%;
}
.field:hover {
  color: white !important;
  background-color: #0300a3 !important;
}

#mySelect .v-select .dropdown-toggle .v-select__selections {
  border: none;
}

.v-application--is-ltr .v-text-field .v-label {
  color: #0300a3;
}

.theme--light.v-select .v-select__selections {
  color: #0300a3 !important;
  font-weight: 500;
}

.theme--light.v-input input {
  color: #0300a3 !important;
  font-weight: 500;
}

.logo_cb {
  position: absolute;
  right: 5%;
  top: 25%;
  z-index: 1;
}

.btn_WA {
  position: fixed !important;
  top: 80% !important;
  left: 5% !important;
}

.img_wa {
  width: 50px;
}

.container_img_principal {
  width: 50% !important;
  text-align: center !important;
  padding: 1px !important;
}
.img_principal {
  display: block;
  margin: auto;
}
.centerVideo {
  text-align: center !important;
  max-width: 100% !important;
}

@media (min-width: 600px) {
  .logo_sura {
    width: 20%;
  }
}


@media (max-width: 600px) {
  .container_logos {
    height: 5%;
  }
  .logo_sura {
    width: 50%;
  }

  .logo_cb {
    position: inherit;
  }

  .container_img_principal {
    min-width: 100% !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .img_principal {
    display: block;
    margin: auto;
  }
}

@media (min-width: 200px) and (max-width: 599px) {

  // dispositivos pequeños
  .centerVideo {
    text-align: center !important;
    max-width: 100% !important;
  }

  .logo_cb {
    position: inherit;
  }

  .container_img_principal {
    margin-top: -8% !important;
    width: 100% !important;
    text-align: center !important;
    padding: 0 !important;
  }

  .img_principal {
    display: block;
    margin: auto;
  }
}

</style>
