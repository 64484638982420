import Router from 'vue-router'
import Vue from 'vue'
import homePage from '../views/homePage.vue'


const TYC = () => import(/* webpackChunkName: "ChatPage"  */ '../views/terminosycondiciones.vue')


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: homePage,
        },
        {
            path: '/terminos-y-condiciones',
            name: 'terminos-y-condiciones',
            component: TYC,
        },
        
    ]
})

export default router
