import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify/lib';
import VueMoment from 'vue-moment'
import Vue2Filters from 'vue2-filters'
import VueYoutube from 'vue-youtube'
import router from './router/index'
 
Vue.use(VueYoutube)
Vue.use(Vue2Filters)
Vue.use(VueMoment)
Vue.config.productionTip = false
Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0300a3',
        secondary: '#0300a3',
        error: '#b71c1c',
      },
    },
  },
})


new Vue({
  vuetify,
  render: h => h(App),
  router,
}).$mount('#app')
